<template>
  <div class="time-select">
    <label class="time-select__label" for="input">{{ label }}</label>
    <div class="time-select__input" @click="openDropDown">
      <div class="time-select__input-value">{{ `${selectedHoursLocal}:${selectedMinutes}` }}</div>
      <base-icon :screenSizeDependency="true" :width="16" :height="16" :path="changeSelectIcon" />
    </div>
    <div v-if="isDropDownOpen" class="time-select__wrapper">
      <div class="time-select__all-items-wrapper">
        <div class="time-select__hours-items">
          <div
            class="time-select__item"
            v-for="(el, index) in optionListHours"
            :key="index"
            :class="{ __selected: checkSelectedTime(el), __disabled: timeDataCheck(el) }"
            @click="selectHours(el)"
          >
            {{ el }}
          </div>
        </div>
        <div class="time-select__minutes-items">
          <div
            class="time-select__item"
            v-for="(el, index) in optionListMinutes"
            :key="index"
            :class="{ __selected: checkSelectedTime(el, true), __disabled: is24Hours || timeDataCheck(el, true) }"
            @click="selectMinutes(el)"
          >
            {{ el }}
          </div>
        </div>
      </div>
      <div v-if="$scopedSlots.append && !appendIconPath" class="time-select__append-content">
        <slot name="append" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { selectProps } from "@/lib/helpers/propsTypes";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";
import { TIME_TYPE } from "@/views/SchedulePage/helpers";

const props = defineProps({
  selectedHours: { type: String | Number, default: "00" },
  selectMinutes: { type: String | Number, default: "00" },
  optionListHours: { type: Array },
  optionListMinutes: { type: Array },
  timeData: { type: Object },
  typeTime: { type: Number },
  ...selectProps,
});
const emits = defineEmits(["update:selectedHours", "update:selectedMinutes"]);

const selectedOption = computed(() => props.optionList?.find(el => el === props.value));

const isDropDownOpen = ref(false);
const selectedHoursLocal = ref(props.selectedHours);
const selectedMinutes = ref(props.selectMinutes === 0 ? "00" : props.selectMinutes);

watch(selectedHoursLocal, () => {
  emits("update:selectedHours", selectedHoursLocal.value);
});

watch(selectedMinutes, () => {
  emits("update:selectedMinutes", selectedMinutes.value);
  isDropDownOpen.value = false;
});

const is24Hours = computed(() => selectedHoursLocal.value == "00" || selectedHoursLocal.value == "24");

const timeDataCheck = (time, isMinute) => {
  if (time == "00" && !isMinute) time = 24;

  const localHour = Number(selectedHoursLocal.value) === 0 ? 24 : Number(selectedHoursLocal.value);
  const timeHourBegin = props.timeData.begin.hour == 0 ? 24 : props.timeData.begin.hour;
  const timeHourEnd = props.timeData.end.hour == 0 ? 24 : props.timeData.end.hour;

  if (isMinute && (localHour == timeHourBegin || localHour == timeHourEnd)) return false;

  if (props.typeTime === TIME_TYPE.BEGIN) {
    if (time >= timeHourEnd) return true;
  } else if (props.typeTime === TIME_TYPE.END) {
    if (time <= timeHourBegin) return true;
  }

  return false;
};

const checkSelectedTime = (time, isMinute) => {
  return isMinute
    ? time == selectedMinutes.value
    : time == selectedHoursLocal.value || (time == "00" && selectedHoursLocal.value == "24");
};
const selectHours = value => {
  selectedHoursLocal.value = value;
};
const selectMinutes = value => {
  selectedMinutes.value = value;
};
const openDropDown = () => {
  isDropDownOpen.value = !isDropDownOpen.value;
};

const changeSelectIcon = computed(() => {
  return isDropDownOpen.value === true ? "arrow-up.svg" : "arrow-down.svg";
});
const isSelected = index => props.optionList.findIndex(i => i === selectedOption.value) === index;
</script>
<style lang="scss" scoped>
.time-select {
  position: relative;
  width: 100%;
  &__input {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: var(--sp-2);
    border-radius: 5px;
    border: 2px solid var(--color-neutral-200);
    background: var(--color-basic-white);
    color: var(--color-neutral-900);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: var(--sp-1);
  }
  &__wrapper {
    position: absolute;
    width: 100%;
    background: white;
    z-index: 1;
  }
  &__all-items-wrapper {
    height: 136px;
    display: flex;
    justify-content: space-between;
    gap: var(--sp-1);
  }
  &__hours-items {
    overflow-y: scroll;
    border-radius: 5px;
    border: 2px solid var(--color-neutral-200);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__minutes-items {
    border-radius: 5px;
    border: 2px solid var(--color-neutral-200);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__item {
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    padding: var(--sp-2) var(--sp-3);
    display: flex;
    flex-direction: column;
    &.__selected {
      background: var(--color-neutral-50);
      color: var(--color-primary-main);
    }
    &.__disabled {
      background: var(--color-neutral-50);
      color: var(--color-neutral-400);
      pointer-events: none;
    }
    &:hover {
      background: var(--color-neutral-50);
    }
  }
  &__select {
    background-repeat: no-repeat, repeat;
    background-position: right 10px top 50%, 0 0;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='Component 1'%3E%3Cpath id='Vector' d='M13 6L8 11L3 6' stroke='%230053AD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
}
select::-ms-expand {
  padding: 2em;
  color: red;
  background: black;
}
</style>
